import React, { Component } from 'react';

class subfooter extends Component {

  render() {
    return (
      <footer className="sub-footer bg-blue text-center">
        <span>Copyright @ 2018. All rights reserved.</span>
      </footer>
    );
  }
}

export default subfooter;
